@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
	background-color: white;
	box-sizing: border-box;
}

@layer base {
	html {
		@apply text-s-black;
	}
	h1 {
		@apply text-4xl;
		@apply font-semibold;
	}
	h2 {
		@apply text-2xl;
		@apply font-medium;
	}
	h3 {
		@apply text-xl;
		@apply font-medium;
	}
	h4 {
		@apply text-lg;
		@apply font-medium;
	}
	h5 {
		@apply text-base;
		@apply font-medium;
	}
	p {
		@apply text-sm;
	}
}

.noScrollBar {
	-ms-overflow-style: none;
	scroll-behavior: smooth;
}

.noScrollBar::-webkit-scrollbar {
	display: none;
}

.quizTimer {
	animation: color_change 1s infinite alternate;
}

@keyframes color_change {
	from {
		color: #a60000;
	}
	to {
		color: Red;
	}
}

@media screen and (max-width: 500px) {
	body {
		background-color: #f5f5f6;
	}
}

* {
	margin: 0;
	padding: 0;
}

.alert-background-enter {
	opacity: 0;
	background-color: rgba(0, 0, 0, 0);
}

.alert-background-enter-active {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.2);
	transition: all 300ms;
}

.alert-background-exit {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.2);
}

.alert-background-exit-active {
	opacity: 0;
	background-color: rgba(0, 0, 0, 0);
	transition: all 300ms;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
	position: relative;
	display: flex;
	flex: 1;
}
.rdtPicker {
	display: none;
	position: absolute;
	width: 250px;
	padding: 4px;
	top: 48px;
	z-index: 99999 !important;
	background: #fff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border: 1px solid #f9f9f9;
}

.rdt > input {
	padding: 12px 14px;
	border: 1px solid #87929b;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	flex: 1;

	color: #141517;
	font-size: 18px;
	font-style: normal;
	font-weight: normal;
	line-height: 21px;
	font-family: "Source Sans Pro", sans-serif;
}

.rdt > input:focus {
	outline: 0;
	border: 2px solid #0071ff;
	padding: 11px 13px;
}

.rdt > input::placeholder,
::-webkit-input-placeholder {
	color: gray;
}

.rdt > input:-ms-input-placeholder {
	color: gray;
}

.rdt > input:hover {
	border-color: #0071ff;
}

.rdtOpen .rdtPicker {
	display: block;
}
.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
}
.rdtPicker td,
.rdtPicker th {
	text-align: center;
	height: 28px;
}
.rdtPicker td {
	cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: #eeeeee;
	cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
	color: #999999;
}
.rdtPicker td.rdtToday {
	position: relative;
}
.rdtPicker td.rdtToday:before {
	content: "";
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #428bca;
	border-top-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
	background-color: #0071ff;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
	color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker th {
	border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
}
.rdtPicker th.rdtSwitch {
	width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 21px;
	vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
	cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}

.rdtPicker tfoot {
	border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}
.rdtPicker button:hover {
	background-color: #eee;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}

.rdtCounters {
	display: inline-block;
}

.rdtCounters > div {
	float: left;
}

.rdtCounter {
	height: 100px;
}

.rdtCounter {
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter .rdtBtn {
	height: 40%;
	line-height: 40px;
	cursor: pointer;
	display: block;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}
.rdtCounter .rdtBtn:hover {
	background: #eee;
}
.rdtCounter .rdtCount {
	height: 20%;
	font-size: 1.2em;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}

.rdtTime td {
	cursor: default;
}
